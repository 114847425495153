import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { AppBar, Toolbar, Typography, Button, Container } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import SyncPage1 from '../pages/SyncPage1';
import SyncPage2 from '../pages/SyncPage2';
import SyncPage3 from '../pages/SyncPage3';
import SyncPage4 from '../pages/SyncPage4';
import AGTheme, { ThemeColors } from '../Theme'
import { RestComponent, HomepageContext } from 'react-frontend-utils'
import { logo } from '../utils/Image'


const AppBarButton = withStyles((theme) => ({
    root: {
        '&:hover': {backgroundColor: ThemeColors.lightTooltipHover}
    }
}))(Button);

class Sync extends RestComponent {
  
  
    styles = {
        appTitle: {
           marginLeft: 10,
           textShadow: "2px 2px #333333",
           fontWeight: "bold",
           fontSize: "200%",
           flexGrow: 1   //fill all space to push other elements to the right edge
        }
    }
  
    _isSupport;
 
    constructor(props) {
        super(props);
        this.state.step = 0;    //start on step 0 - no page (until user is loaded)
        
        this.state.applicationID = props.id ? encodeURIComponent(props.id) : null;  //user asks for a specific application, encode for URL safe
        this.state.group = props.group ? encodeURIComponent(props.group) : null;
        this._isSupport = props.support && props.support === "true" ? true : false;
        
        this.state.membershipID = null;     //membership ID retrieved in Step 1 (or null for new)
        this.state.syncData = null;         //sync data retrieved and modified in Step 2
        this.state.dataToServer = null;     //data to be sent to server at the end of sync      
              
        if (!this.state.applicationID)
            this.state.serverError = "No Application ID";
        
        
    }
    
    componentDidMount() {
        super.componentDidMount(); 
        this._fetchCurrentUser();        
    }

    //Needed to pull in user auth/cookies
    _fetchCurrentUser = () => {
        this.secureJSONFetch("/auth/currentUser", {}, this._checkUserCallback); 
    }


    /**
     * Callback to be executed from fetching the current user
     * @param {Object} response null if there is no current authenticated user, or a JSON object describing the user
     */
    _checkUserCallback = (response) => {
        if (response) {
            this.setState({step: 1});  //jump to first step
            this.props.history.push("/agportal/sync?id=" + this.state.applicationID + "&step=1");
        }
        else
            this.setState({serverError: "Not logged in. Please close this tab and log into the portal again.", applicationID: null});

            
    }
 
    sessionExpired = () => {
        this.setState({serverError: "Session Expired. Please close this tab", applicationID: null});
    }



    //MembershipID has been selected on Step 1, jump to Step 2. (Or null for new Membership)
    _gotoStep2 = (membershipID) => {
        this.props.history.push("/agportal/sync?id=" + this.state.applicationID + "&step=2");
        this.setState({membershipID: membershipID, step: 2});
    }

    //Sync data has been created on Step 2, jump to Step 3
    _gotoStep3 = (syncData) => {
        this.props.history.push("/agportal/sync?id=" + this.state.applicationID + "&step=3&support=" + this._isSupport);
        this.setState({syncData: syncData, step: 3});
    }
    
    //Sync data has been created on Step 3, jump to Step 4
    _gotoStep4 = (dataToServer) => {
        this.props.history.push("/agportal/sync?id=" + this.state.applicationID + "&step=4");
        this.setState({dataToServer: dataToServer, step: 4});
    }

    //Return from Step 2, select a new Membership - null membership ID and sync data
    _goBacktoStep1 = () => {
        this.setState({membershipID: null, syncData: null, step: 1}); 
        this.props.history.push("/agportal/sync?id=" + this.state.applicationID + "&step=1");
    }
    
    //Return from Step 3, clear the sync data, but keep the membership id
    _goBacktoStep2 = () => {
        this.setState({syncData: null, step: 2});        
        this.props.history.push("/agportal/sync?id=" + this.state.applicationID + "&step=2");
    }
    
    //Return from Step 4, clear the dataToServer data, but keep others
    _goBacktoStep3 = () => {
        this.setState({dataToServer: null, step: 3});        
        this.props.history.push("/agportal/sync?id=" + this.state.applicationID + "&step=3&support=" + this._isSupport);
    }


    //Open in a new browser window, to the right of this window
    _manualEdit = () => {

        const url = "https://portal.pool-pass.com/membership?database=" + this.state.group + (this.state.membershipID ? "&id=" + this.state.membershipID : "");
            
        const windowRight = window.screenLeft + window.outerWidth;    
                
        window.open(url, '_blank', 'toolbar=yes, scrollbars=yes, resizable=yes, ' +
                    'top=' + window.screenTop + ',left=' + windowRight);

    }
  
    _onMembershipCreate = (newMembershipId) => {
        this.setState({membershipID: newMembershipId});
    }

    render() {

       
        //Selects the current page to view
        const viewingPage = (() => {
                       
            switch (this.state.step) {

                case 0:
                    return null;

                case 1:   
                    return <SyncPage1 id={this.state.applicationID} nextPageCallback={this._gotoStep2}/>;
              
                case 2:   
                    return <SyncPage2 id={this.state.applicationID} membershipID={this.state.membershipID} prevPageCallback={this._goBacktoStep1} nextPageCallback={this._gotoStep3}/>;
                    
                case 3:
                    return <SyncPage3 id={this.state.applicationID} syncData={this.state.syncData} prevPageCallback={this._goBacktoStep2} nextPageCallback={this._gotoStep4} isSupport={this._isSupport}/>;
                             
                case 4:
                    return <SyncPage4 id={this.state.applicationID} dataToServer={this.state.dataToServer} prevPageCallback={this._goBacktoStep3} onMembershipCreate={this._onMembershipCreate}/>;
              
                default:
                    return <div>Page Not Found</div>;

            }
        })();
        
        const serverErrorMessage = this.state.serverError ? <Typography variant="h5">Server Error: {this.state.serverError}</Typography> : null;
      
        const gutterMargin = 20;
        
        const manualEditTitle = this.state.membershipID ? "Edit in PoolPass" : "Open PoolPass";  
               
        return (
            <HomepageContext.Provider value={{sessionExpiredCallback: this.sessionExpired}}>
    
                <ThemeProvider theme={AGTheme}>
                    <Fragment>

                        {this.getConfirmAlertComponent()  /*inject an alert component*/}  

                        <AppBar position="static" style={{marginBottom: 12, backgroundColor: ThemeColors.appBarBackground}}>
                            <div style={{paddingTop: 0, paddingBottom: 4, paddingLeft: gutterMargin, paddingRight: gutterMargin}}>

                                <Toolbar disableGutters={true}>
                                     {logo}
                                     <Typography variant="h5" style={this.styles.appTitle}>Application Processing Portal</Typography>    
                                     
                                     <AppBarButton color="inherit" onClick={this._manualEdit} style={{marginTop: 10}}>{manualEditTitle}</AppBarButton>
                                     
                                </Toolbar>
                                {this.state.applicationID ? <Typography variant="subtitle1" align='center'>{"PoolPass Sync Application ID " + this.state.applicationID}</Typography> : null}
                            </div>
                        </AppBar>


                        {serverErrorMessage}
                        
                        <Container maxWidth={false} disableGutters={true} style={{paddingLeft: gutterMargin, paddingRight: gutterMargin}}>
                            {viewingPage} 
                        </Container>

                    </Fragment>
                </ThemeProvider>    
            </HomepageContext.Provider>
        );
    }
};

export default withCookies(withRouter(Sync));


