import React, { useState, useRef, Fragment } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Collapse, IconButton, Tooltip, Button, TextField, CircularProgress, Typography, Checkbox, Popover, Container, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ReplyIcon from '@material-ui/icons/Reply';
import PanToolIcon from '@material-ui/icons/PanTool';
import CreateIcon from '@material-ui/icons/Create';
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import CropRotateIcon from '@material-ui/icons/CropRotate';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import SignatureCanvas from 'react-signature-canvas'

import { ThemeColors } from '../Theme';

import { GenericFieldContainer, GenericTextField, GenericMultiTextFields, Instructions, PPIcon, LogicIcon, HiddenIcon, ModifiableIcon, WebhookIcon, MergedIcon, RoundButton } from './FieldSubcomponents';

import { ManageDateField, DateUtils, ManageDecimalField, DragAndDrop, WebcamCapture, ImageCropper, ImageUtils, PopupMenu, RichTextEditor, Email } from 'react-frontend-utils';
import { Application } from '../models/Application'
import { Template } from '../models/Template'

import camera_shutter from '../assets/camera_shutter.wav'



/**
 * Functions in this class render the various specific types of Application Fields. Calling the renderField function on the base element of the field tree
 * must be called and all elements in the tree are rendered. There are three modes of operation:
 * 
 *  Patrons: when patrons are editing and submitting the form
 *  Processors: when processors are viewing (and possibly editing parts of the form)
 *  Template: when someone is drag-and-drop editing the form.  The base field is encapuslated in a DragDropContext
 * 
 * 
 */

//Default parameters passed to the renderField function if not specified
const defaultParams = {
    onChange: null,                     //callback function when field is edited, passes the field and new value. If null, the field cannot be edited by processor
    canEdit: false,                     //set to true if the processor has claimed it, false otherwise - ignored for patron
    onError: null,                      //REQUIRED callback function if there was an error in the field entry, passed the field label and an error message (onChange can still be called) 
    forPatron: true,                    //true if this is for the patron to be filling out, false if for processing
    forTemplate: false,                 //true if this if for editing the Template
    documentUploader: null,             //function that upserts/removes a document, argument are 1. the existing document id (if any), 2. the file to upload (if null, removes), 3. a callback function that receives the new document ID (null for delete)
    documentDownloader: null,           //function the downloads info about a document or the document itself. Arguments are 1. the document id and 2. documentInfoCallback to receive the file Info (if null, the file is downloaded instead)
    copyField: null,                    //function that receives the field to copy as an argument, only needed for Template - can be null for others
    removeField: null,                  //function that receives the field to remove as an argument, only needed for Template - can be null for others
    clipboardField: null,               //field placed on the clipboard by a call to removeField.  If nothing on the clipboard, null.  Only needed for Template
    insertFieldFromClipboard: null,     //function to insert the field on the clipboard as a child, argument is the parent field.  Only needed for Template
    getAvailableFields: null,           //function that returns an array of objects for a PopupMenu, where each item contains a label, icon, and a callback when selected to create the new field - see below for more explanation.  Only needed for Template
    onFieldPropertyEdit: null,          //function that is called when a field is selected for property edit, only needed for Template   
    fieldBeingEdited: null             //Name of the field being edited in any, only for Template
};


const cameraShutterAudio = new Audio(camera_shutter);

const displayImageHeight = 200;     //for displaying image preview, default height

const displayDocWidth = 200;
const displayDocHeight = 80;

const signatureCanvasHeight = 300;

const maxImageDimension = 1536;


const isMobile = () => {
    return window.innerWidth < 600;
};

/**
 * Recursive function to render each field from an Application
 * @param {Object} field the field to render, from the field tree in the Application
 * @param {Integer} index the index into the location of the child in a group
 * @param {Object} params an object defined in defaultParams, for working with the fields 
 * @param {Boolean} submitTried true after a patron tries at least once to submit Application, false in other cases
 * @param {Integer} indent to render with indentation - default 0
 * @param {boolean} true if the field is being dragged
 * @param {Object} dragHandleProps for draggable template elements, these are the params that can be spread in the desired element for the drag handle
 * @returns JSX component
 */
export function renderField(field, index = 0, params = defaultParams, submitTried = false, indent = 2, isDragging = false, dragHandleProps = null) {

    if (!params.forTemplate && field.hidden)  //don't show hidden fields unless for template
        return null;

    const beforeCapture = (result, baseField) => {              
        const { draggableId } = result;
        setCollapseOnDrag(baseField, draggableId, true);  //collapse the dragging field
    };
    
    
    const dragEnd = (result, baseField) => {
        
        const { destination, source, draggableId } = result;
        
        setCollapseOnDrag(baseField, draggableId, false);  //expand the dragging field

        if (!destination) //dropping over nothing
            return;
   
        //dropping over itself
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;

        const movedField = Template.findFieldByName(baseField, draggableId);  //find the Field being dragged
        const dropOnField = Template.findFieldByName(baseField, destination.droppableId);  //find the Field that holds the droppable (can only drop within our own group)
     
        if (dropOnField.type === "FieldGroup" || dropOnField.type === "PagedFieldGroup") {
            dropOnField.children.splice(source.index, 1);  //Remove from original position
            dropOnField.children.splice(destination.index, 0, movedField);  //Insert in new position
        }
    
        params.onChange(movedField); //re-render  
    };
    
    
    
    //Insert all the new fields in the fields array as children of the parent
    const insertFields = (parent, fields) => {
            
        if (parent.type === "FieldGroup" || parent.type === "PagedFieldGroup") {
            for (const field of fields)
                parent.children.unshift(field);  //Insert at beginning
        }
        else if (parent.type === "CloningFieldGroup" && !parent.template)  //only the first field can be inserted
            parent.template = fields[0];

         
        params.onChange(parent); //re-render  
    };

    let component;

    const standardFieldProps = {style: {marginLeft: 6}, field: field, params: params, submitTried: submitTried, key: Application.uniqueFieldName(field)};

    switch (field.type) {

        case "FieldGroup":
            component = <FieldGroup field={field} params={params} submitTried={submitTried} indent={indent} key={Application.uniqueFieldName(field)} isDragging={isDragging} dragHandleProps={dragHandleProps}/>;
            break;

        case "PagedFieldGroup":
            component = <PagedFieldGroup field={field} params={params} submitTried={submitTried} indent={indent} key={Application.uniqueFieldName(field)} isDragging={isDragging} dragHandleProps={dragHandleProps}/>;
            break;
            
        //For Patrons, we render a CloningFieldGroup.  This will never occur for Processors, as CloningFieldGroups get converted to regular FieldGroups when
        //the Application is processed.  For Templates, we use a FieldGroup that that may only have one child (the clone-template)
        case "CloningFieldGroup":  
            if (params.forPatron)
                component = <CloningFieldGroup field={field} params={params} submitTried={submitTried} indent={indent} key={Application.uniqueFieldName(field)}/>; 
            else if (params.forTemplate)
                component = <FieldGroup asCloningFieldGroup={true} field={field} params={params} submitTried={submitTried} indent={indent} key={Application.uniqueFieldName(field)} isDragging={isDragging} dragHandleProps={dragHandleProps}/>;
            else
                component = <FieldGroup field={field} params={params} submitTried={submitTried} indent={indent} key={Application.uniqueFieldName(field)} isDragging={isDragging} dragHandleProps={dragHandleProps}/>;
            break;
        
        case "TextField":
            component = <FormTextField {...standardFieldProps}/>;
            break;
            
        case "NumericField":
            component = <NumericField {...standardFieldProps}/>;
            break;
        
        case "DateField":
            component = <DateField {...standardFieldProps}/>;
            break;
            
        case "ImageField":
            component = <ImageField {...standardFieldProps}/>;
            break;
            
        case "DocumentField":
            component = <DocumentField {...standardFieldProps}/>;
            break;

        case "SignatureField":
            component = <SignatureField {...standardFieldProps}/>;
            break;
            
        case "SelectField":
            component = <SelectField {...standardFieldProps}/>;
            break;
            
        case "CheckboxField":
            component = <CheckboxField {...standardFieldProps}/>;
            break;
            
        case "AddressField":
            component = <AddressField {...standardFieldProps}/>;
            break;

        case "EmergencyContactField":
            component = <EmergencyContactField {...standardFieldProps}/>;
            break;

        case "EmailField":
            component = <EmailField {...standardFieldProps}/>;
            break;
   
        case "CalcResultField":
            component = <CalcResultField {...standardFieldProps}/>;
            break;
           
        case "RichTextHtmlField":
            component = <RichTextHtmlField {...standardFieldProps}/>;
            break;

        default:  //highlight an unknown field
            component = <div style={{color: 'red', marginLeft: 6, padding: 5, border: '1px solid red'}} key={Application.uniqueFieldName(field)}>Unknown Field Type</div>;
            break;

    }
    
               
    //To populate the menu items in the Add Field PopupMenu, we call a function on params called getAvailableFields - this function will return a list of objects
    //for the popup menu containing label, icon, and a callback function that is called when selected.  We pass to getAvailableFields, a function that should be called
    //by the callback function with the argument of an array of the newly created fields to insert as a child of "field" (its parent)
    const addFieldItems = params.forTemplate ? params.getAvailableFields((createdFields) => insertFields(field, createdFields)) : null;
        
    
    
    //Template rendering - the base needs to be surrounded by a drag-and-drop context, with a button to add new fields
    if (params.forTemplate && field.isBase) {          
        return <DragDropContext onBeforeCapture={(result) => beforeCapture(result, field)}
                                onDragEnd={(result) => dragEnd(result, field)}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginRight: 5, marginBottom: 5}}>
                        <Typography variant='body1' style={{fontSize: 12, fontStyle: 'italic', color: 'gray', marginLeft: 5}}>When editing templates, set field default values in the field data</Typography>
                        <div>
                            <PopupMenu menuIcon={(<AddCircleOutlineIcon style={{color: ThemeColors.addColor, fontSize: 18}}/>)}  
                                                    menuItems={addFieldItems} 
                                                    onError={ (name, error) => params.onError(name, error.toString()) }
                                                    menuTooltipText={"Add a field"}/>
                            {params.clipboardField ? 
                                    <Tooltip title="Insert field from clipboard">
                                        <IconButton onClick={() => params.insertFieldFromClipboard(field)}>
                                           <GetAppIcon style={{color: ThemeColors.addColor, fontSize: 18}}/>
                                        </IconButton>
                                    </Tooltip>
                                 : null
                            }
                        </div>
                    </div>
                    {component}
                </DragDropContext>; 
    }
    
    //Else, for all other template elements, surround by a template container with functions for adding, deleting, modifying, etc.
    else if (params.forTemplate) {
        const color = isDragging ? ThemeColors.appBarBackground : ThemeColors.templateBackground;
    
        //Fields can be added if the field type is a container, like a FieldGroup, or a CloningFieldGroup - but only when there's not already a template set
        const canAdd = field.type === "FieldGroup" || field.type === "PagedFieldGroup" || (field.type === "CloningFieldGroup" && field.template === null);
    
        const addTooltip = (field.type === "FieldGroup" || field.type === "PagedFieldGroup") ? "Add a field to this Group" : "Create the template for this Cloning Group";
    
        return <div style={{display: 'flex', marginBottom: 3, border: '1px solid ' + color, backgroundColor: 'white'}}>         
                    <div style={{flexGrow: 2}}>
                        {component}
                    </div>
                    <div style={{marginLeft: 20}}>
                        <Typography align='right' variant='body1' style={{fontSize: 12,  color: ThemeColors.appBarBackground, textTransform: 'uppercase'}}>{field.type.replace("Field", "")}</Typography>
                        <Typography align='right' variant='body1' style={{fontSize: 12,  fontStyle: 'italic', color: ThemeColors.appBarBackground, marginTop: -4}}>{field.name}</Typography>        
                        {field.hiddenLogic ? <LogicIcon/> : null}
                        {field.initiallyHidden ? <HiddenIcon/> : null}
                        {field.modifiable ? <ModifiableIcon/> : null}
                        {field.isWebhookField ? <WebhookIcon/> : null}
                        {field.merged ? <MergedIcon/> : null}
                    </div>
                    <div {...dragHandleProps} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 5, paddingRight: 2, marginLeft: 4, backgroundColor: color, borderRight: '1px solid ' + ThemeColors.templateBorder}}>
                        <div style={{float: 'right', marginRight: 5}}>
                            <Tooltip title={"Drag and move the " + field.type.replace("Field", "") + " field"}>
                                <PanToolIcon style={{float: 'right', color: 'gray', fontSize: 18}}/>
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{backgroundColor: color}}>
                       
                        {canAdd ?
                            <div>
                                <div style={{width: 24, marginLeft: 6}}>                                
                                    <PopupMenu menuIcon={(<AddCircleOutlineIcon style={{color: ThemeColors.addColor, fontSize: 18}}/>)}  
                                                            menuItems={addFieldItems} 
                                                            onError={ (name, error) => params.onError(name, error.toString()) }
                                                            menuTooltipText={addTooltip}/>
                                </div> 
                                {params.clipboardField ? 
                                    <div>
                                        <Tooltip title="Insert field from clipboard">
                                            <IconButton onClick={() => params.insertFieldFromClipboard(field)} style={{width: 30, marginTop: -4}}>
                                               <GetAppIcon style={{color: ThemeColors.addColor, fontSize: 18}}/>
                                            </IconButton>
                                        </Tooltip>
                                     </div>
                                     : null
                                }
                            </div>
                            : null
                        }
                        <div>
                            <Tooltip title={"Edit properties for field \"" + field.name + "\""}>
                                <IconButton onClick={()=> params.onFieldPropertyEdit(field)} style={{width: 30, marginTop: -4}}>
                                    <CreateIcon style={{fontSize: 18, color: field.name === params.fieldBeingEdited ? ThemeColors.editGreen : 'gray'}} />
                                </IconButton>
                            </Tooltip>                        
                        </div>
                        <div>
                            <Tooltip title="Copy this field and place it on the clipboard (replacing clipboard contents)">
                                <IconButton onClick={() => params.copyField(field)} style={{width: 30, marginTop: -4}}>
                                   <FileCopyIcon style={{fontSize: 18, color: 'gray'}}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div>          
                            <Tooltip title="Remove this field and place it on the clipboard (replacing clipboard contents)">
                                <IconButton onClick={() => params.removeField(field)} style={{width: 30, marginTop: -4}}>
                                   <ReplyIcon style={{transform: 'scaleX(-1)', fontSize: 18, color: ThemeColors.declinedRed}}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>                    
               </div>;
    }
    else 
        return component;
    
}
    
    
    
//For fields that themselves contain droppables, we must collapse them during drag because otherwise weird rendering effects occur with the dnd.
//We set a flag within the field that is checked in the field renderer and collapse is set based on that flag
function setCollapseOnDrag(baseField, fieldNameToFind, collapse) {
    
    const foundField = Template.findFieldByName(baseField, fieldNameToFind);
    if (foundField && (foundField.type === "FieldGroup" || foundField.type === "PagedFieldGroup" || foundField.type === "CloningFieldGroup")) {
        foundField.collapseOnDragStart = collapse;
    }
}    
    
    
//True if the field should be rendered read only
function readOnlyField(field, params) {
    
    //Patrons and Template can always edit field
    if (params.forPatron || params.forTemplate)
        return false;
    
    //For application processor, but viewing application only (no callback) - is read only
    if (!params.onChange || !params.canEdit)
        return true;
    
    //For application processor, but field is not modifiable - is read only
    if (!field.modifiable)
        return true;

    return false;  //otherwise, application processor can modify
}
    


//---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------- FIELD GROUP ---------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

const GROUP_INDENT = isMobile() ? 10 : 30;
    
    
//For patrons, render the group by showing the children indented. The patron cannot hide the children.
//
//For processors, render the group by adding a reveal indicator and then rendering the children. The base group does not contain a reveal indicator.
//Except for the base group, the field group is initially not revealed for processors.
//
//For template editing, a Field group places its children in a Droppable container, and wraps each child as a Draggable (including nested groups). If the
//prop asCloningFieldGroup is set to true, instead of rendering the children, we render the CloningFieldGroup's template
//
//Each child is indented by the group indent, to create the indented tree.
function FieldGroup(props) {
                
    const [revealed, setRevealed] = useState(props.field.isBase);  //initially, not revealed unless the first element
    
    const show = (revealed || props.params.forPatron) && !props.field.collapseOnDragStart; //show when revealed and always show for patron, hide if marked dragging
    
    const icon = show ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;
    
    const groupLabel = props.field.label ? props.field.label : props.field.name;
        
    const children = props.asCloningFieldGroup ? (props.field.template ? [props.field.template] : []) : props.field.children;  //render the template or the children, depending on who this is for
    
    return (
            <div style={{...props.style, marginBottom: 20, marginRight: props.field.isBase ? 10 : 0}}>
    
                {props.field.isBase ? null :
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {!props.params.forPatron ? 
                            <IconButton edge="start" onClick={() => setRevealed(!revealed)}>
                                {icon}                          
                            </IconButton>   
                            : null
                         }
                        <Typography style={{marginLeft: props.params.forPatron ? 10 : -5, fontSize: 16}} variant="button">{groupLabel}</Typography>  
                    
                        <PPIcon isPPSyncField={props.field.isPPSyncField} isPatron={props.params.forPatron}/>
                        <Instructions instructions={props.field.instructions} forTemplate={false} isTemplate={props.params.forTemplate} forPatron={false} isPatron={props.params.forPatron}/>
                    </div>                    
                }
                
                <Instructions instructions={props.field.instructions} forTemplate={true} isTemplate={props.params.forTemplate}  forPatron={true} isPatron={props.params.forPatron}/>
                    
                <Collapse in={show} timeout={150} style={{marginLeft: props.indent}}>

                    {props.asCloningFieldGroup ? 
                        <Typography style={{color: ThemeColors.templateMagenta}} variant="button">Clone Template</Typography>  
                        : null
                    }

                    {props.params.forTemplate ? 
                        <Droppable droppableId={props.field.name} type={props.field.name}>
                            {(provided, snapshot) => 

                                <div ref={provided.innerRef} {...provided.droppableProps} style={{backgroundColor: snapshot.isDraggingOver || props.field.isBase ? ThemeColors.templateGray : 'white'}}>

                                    {children.map((child, index) => 

                                        <Draggable draggableId={child.name} key={child.name} index={index}>
                                            {(provided, snapshot) => {
                                                return  <div ref={provided.innerRef}  {...provided.draggableProps}>   
                                                            {renderField(child, index, props.params, props.submitTried, GROUP_INDENT, snapshot.isDragging, provided.dragHandleProps)}
                                                        </div>;
                                            }}
                                        </Draggable>  //Every child is a draggable
                                    )}

                                    {provided.placeholder}
                                </div>
                            }
                        </Droppable>
                        :
                        <div>
                            {children.map((child, index) => renderField(child, index, props.params, props.submitTried, GROUP_INDENT))}
                        </div>
                    }
                </Collapse> 
                
            </div>
        );
         
}



//---------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------ PAGED FIELD GROUP --------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------
    
    
//For patrons, render the group by showing one child at a time, with buttons to move forward or back (unless back is disabled)
//Hidden children are not part of the paging.
//
//For processors and template rendering, the functionality should be the same as the regular FieldGroup
//
function PagedFieldGroup(props) {
                
    const [displayedPage, setDisplayedPage] = useState(0);  //initially, first page displayed

    if (props.params.forTemplate || !props.params.forPatron)  //template, processor - just render a regular group
        return <FieldGroup {...props}/>;

    const groupLabel = props.field.label ? props.field.label : props.field.name;
            
    const visibleChildren = props.field.children.filter(child => !child.hidden);

    const childToRender = visibleChildren[displayedPage];

    const hasNext = displayedPage < visibleChildren.length-1;  //next available if we're not at the end
    const hasPrev = displayedPage > 0 && !props.field.pageBackDisabled;  //prev available if we're not at the beginning, and page back is not disabled


    const gotoNext = () => {
        setDisplayedPage(displayedPage+1);
    }
    const gotoPrev = () => {
        setDisplayedPage(displayedPage-1);
    }
    const jumpTo = (page) => {
        setDisplayedPage(page);
    }

    const buttonWidth = 100;
    const pages = [...Array(visibleChildren.length).keys()];

    console.log(props.field, pages)
    return (
            <div style={{...props.style, marginBottom: 20}}>
    
                <Typography style={{marginLeft: 10, fontSize: 16}} variant="button">{groupLabel}</Typography>  
                <Instructions instructions={props.field.instructions} forTemplate={true} isTemplate={props.params.forTemplate} forPatron={true} isPatron={props.params.forPatron}/>
                    
                {childToRender ? renderField(childToRender, displayedPage, props.params, props.submitTried, GROUP_INDENT) : null}
                 
                <div style={{display: 'flex', marginLeft: '20%', marginRight: '20%', alignItems: 'center', justifyContent: 'space-between'}}>
                    {hasPrev ? <Button style={{fontSize: 14, width: buttonWidth}} variant='contained' onClick={gotoPrev}>{"Previous"}</Button> : <div style={{width: buttonWidth}}/>}
                    {props.field.allowRandomPageAccess ? 
                        <Fragment>
                            {pages.map((page, index) => <RoundButton key={index} label={page} onClick={()=>jumpTo(page)} isSelected={page === displayedPage}/>)}
                        </Fragment>
                        : null
                    }
                    
                    {hasNext ? <Button style={{fontSize: 14, width: buttonWidth}} variant='contained' onClick={gotoNext}>{"Next"}</Button> : <div style={{width: buttonWidth}}/>}
                </div>

            </div>
        );
         
}



//---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------- CLONING FIELD GROUP -------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

//Render the group by rendering the children. The add and subtract buttons allow children to be added, up to the 
//number of children available.
function CloningFieldGroup(props) {
                
    //if we've already tried to submit, then notify if missing children
    const missingChildren = props.submitTried && props.field.minChildren > 0 && props.field.children.length < props.field.minChildren;

    const [update, setUpdate] = useState(0); // new integer each time, to force component to update when child is added or removed

    const addChild = () => {
        
        Application.cloneTemplate(props.field);
        setUpdate(update+1);
        props.params.onChange(props.field);
    };
    
     const removeChild = () => {
  
        props.field.children.pop();
        setUpdate(update+1);
        props.params.onChange(props.field);  
    };
    
                    
    const canAdd = props.field.maxChildren > 0 ? props.field.children.length < props.field.maxChildren : true;
    const canRemove = props.field.minChildren > 0 ? props.field.children.length > props.field.minChildren : (props.field.children.length > 0);
   
    return (
            <div style={{...props.style, marginBottom: 20}}>
    
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography style={{marginLeft: 10, marginRight: 10, fontSize: 16}} variant="button">{props.field.label ? props.field.label : props.field.name}</Typography>  
                    <IconButton edge="start" onClick={addChild} disabled={!canAdd}>
                        <AddCircleOutlineIcon/>                         
                    </IconButton>                      
                    <IconButton edge="start" onClick={removeChild} disabled={!canRemove}>
                        <RemoveCircleOutlineIcon/>                         
                    </IconButton>   
                    
                    <Typography style={{marginLeft: 10, marginRight: 10, fontSize: 16, color: 'gray'}} variant="body2">{"Count: " + props.field.children.length}</Typography>
                    
                    {missingChildren ? 
                        <Typography style={{marginLeft: 10, fontStyle: 'italic', color: 'red'}} variant="body2">{"Minimum " + props.field.minChildren + " Required"}</Typography>
                        : null
                    }
                </div> 

                <Instructions instructions={props.field.instructions} forTemplate={true} isTemplate={props.params.forTemplate}  forPatron={true} isPatron={props.params.forPatron}/>
                   
                <div style={{marginLeft: props.indent}}>
                    {props.field.children.map((child, index) => renderField(child, index, props.params, props.submitTried, GROUP_INDENT))}
                </div> 
                        
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {props.field.children.length > 0 && canAdd ? <Button style={{fontSize: 14}} variant='contained' onClick={addChild}>{"+ Add Another " + props.field.template.label}</Button> : null}
                </div>

            </div>
        );
         
}



//--------------------------------------------------------------------------------------------------------------------------------- 
//----------------------------------------------------- TEXT FIELD ----------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

//Using GenericMultiTextFields with just one field    

function FormTextField(props) {
    
    let initialValue = props.field.patronData;
    if (!initialValue)
        initialValue = "";
        
    let components = [{label: props.field.label + (props.field.required ? " *" : ""),   value: initialValue, xs: 12}];
                     
    const readOnly = readOnlyField(props.field, props.params);

    return (
        <GenericMultiTextFields {...props} isReadOnly={readOnly} components={components}/>
    );
    
}    



//----------------------------------------------------- NUMERIC FIELD ---------------------------------------------------------------

    
function NumericField(props) {
        
    const initialValue = props.field.patronData;
       
    return (
        <GenericFieldContainer style={props.style} field={props.field} submitTried={props.submitTried} params={props.params}>

            <ManageDecimalField label={props.field.label + (props.field.required ? " *" : "")}
                                json={props.field.name}  
                                initialValue={initialValue ? initialValue : 0}
                                autoAccept={true}
                                hasNever={false}
                                hasNegative={true}
                                minValue={props.field.minValue}
                                maxValue={props.field.maxValue}
                                hideButtons={props.field.hideButtons}
                                decimalPlaces={props.field.decimalPlaces}
                                isReadOnly={readOnlyField(props.field, props.params)}
                                onFieldChange={(fieldName, userValue) => {
                                       props.field.patronData = userValue;
                                       if (props.params.onChange)
                                           props.params.onChange(props.field, userValue);

                                }}/>

            {props.field.isCurrencyValue ?     
                <Typography style={{marginLeft: 10, fontSize: 16}} variant="body2">{props.field.currency ? props.field.currency : "USD"}</Typography>
                :
                null
            }
        </GenericFieldContainer>

    );
    
    
}    



//---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------- EMAIL FIELD ----------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

    
function EmailField(props) {
    
    const [invalid, setInvalid] = useState(false);  //initially, not invalid

    const req = props.field.required ? " *" : "";

    return (
        <GenericFieldContainer style={props.style} field={props.field} submitTried={props.submitTried} params={props.params} invalid={invalid}>

             <GenericTextField label={props.field.label + req}                              
                               initialValue={props.field.patronData}
                               isReadOnly={readOnlyField(props.field, props.params)}
                               onFieldChange={(userValue) => {

                                    if (userValue) {
                                        if (!Email.validateEmail(userValue)) {
                                            props.params.onError(props.field.label, "Invalid email address (check for whitespaces, invalid characters, and valid domain)");
                                            props.field.hasError = true;
                                            setInvalid(true);
                                            return;    
                                        }
                                    }
                                    props.field.hasError = false;
                                    props.field.patronData = userValue;
                                    setInvalid(false);
                                    if (props.params.onChange)
                                        props.params.onChange(props.field, userValue);
                              }}
            />
        </GenericFieldContainer>
    );
}

//---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------- DATE FIELD ----------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

    
function DateField(props) {
    
    const [invalid, setInvalid] = useState(false);  //initially, not invalid

    const dateFormat = props.field.dateFormat && props.field.dateFormat === 'US' ? DateUtils.DateFormatType.US : DateUtils.DateFormatType.ISO8601;
    
    let initialValue = props.field.patronData;
    if (initialValue)
        initialValue = DateUtils.dateFormat(DateUtils.parseJsonDate(initialValue), dateFormat);     // local date format for display
        
    return (
        <GenericFieldContainer style={props.style} field={props.field} params={props.params} submitTried={props.submitTried} invalid={invalid}>

            <ManageDateField label={props.field.label + (props.field.required ? " *" : "")}
                             json={props.field.name}  
                             style={{maxWidth: 300}}
                             initialValue={initialValue}
                             dateFormat={dateFormat}
                             hasNever={true}
                             neverText=""
                             autoAccept={true}
                             isReadOnly={readOnlyField(props.field, props.params)}
                             calendarColor={ThemeColors.calendarColor}
                             onParseError={(label, errorText) => {
                                props.params.onError(props.field.label, errorText);        
                             }}
                             onFieldChange={(fieldName, newValue) => {
                                    
                                    if (newValue !== null) {  //Check valid dates
                                        
                                        const enteredDate = DateUtils.parseJsonDate(newValue, true);    // UTC value of the entered date
                                        
                                        // Compare UTC times of minDate and enteredDate
                                        if (props.field.minDate && enteredDate.getTime() < DateUtils.parseJsonDate(props.field.minDate, true).getTime()) {
                                            props.params.onError(props.field.label, "Date may not be before " + DateUtils.dateFormat(props.field.minDate, dateFormat));
                                            props.field.hasError = true;
                                            setInvalid(true);
                                            return;                                           
                                        }
                                        
                                        // Compare UTC times of maxDate and enteredDate
                                        if (props.field.maxDate && enteredDate.getTime() > DateUtils.parseJsonDate(props.field.maxDate, true).getTime()) {
                                            props.params.onError(props.field.label, "Date may not be after " + DateUtils.dateFormat(props.field.maxDate, dateFormat));
                                            props.field.hasError = true;
                                            setInvalid(true);
                                            return;                                         
                                        }
                                        
                                        const now = (new Date()).getTime();  // UTC value of now
                                        
                                        // Compare UTC times of now and enteredDate
                                        if (props.field.mustBeInPast && enteredDate.getTime() > now) {
                                            props.params.onError(props.field.label, "Date must be in the past, not the future");
                                            props.field.hasError = true;
                                            setInvalid(true);
                                            return;
                                        }
                                        // Compare UTC times of now and enteredDate
                                        if (props.field.mustBeInFuture && enteredDate.getTime() < now) {
                                            props.params.onError(props.field.label, "Date must be in the future, not the past");
                                            props.field.hasError = true;
                                            setInvalid(true);
                                            return;
                                        }
                                    
                                    }

                                    props.field.hasError = false;
                                    props.field.patronData = newValue;
                                    setInvalid(false);
                                    if (props.params.onChange)
                                        props.params.onChange(props.field, newValue);

                             }}/>
        </GenericFieldContainer>

    );
}
    
    
    
//---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------- IMAGE FIELD ---------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

    
function ImageField(props) {
        
    const isReadOnly = readOnlyField(props.field, props.params);
        
    //If for the patron or template, show the default image (if any), if for processing, show the patron image data
    const initialImage = props.field.patronData;

    const [displayedImage, setDisplayedImage] = useState(initialImage);   //the image displayed
    const [imageToCrop, setImageToCrop] = useState(null);                 //the image to crop, initially none
    const [croppedImageData, setCroppedImageData] = useState(null);       //dimensions and size
    
    const [showCropper, setShowCropper] = useState(false);                
    const [showCapture, setShowCapture] = useState(false);              
    
       
    const label = props.field.label + (props.field.required ? " *" : "");
    
    //Callback when File is selected, read the file and set it as the image to crop
    const cropFile = (file) => {
        
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
 
        if (!acceptedImageTypes.includes(file['type'])) {
            props.params.onError(props.field.label, "File is not a valid image type");
            setImageToCrop(null); 
            return;
        }

        ImageUtils.readImageFile(file, (dataURL) => {
            setImageToCrop(dataURL);     //we read the file, set the image to crop  
            setShowCropper(true);        //turn on the cropper mode
        });
    
    };
    
    //Callback when a file is dropped on the image
    const imageFileDropped = (file) => {
        if (showCropper || showCapture || isReadOnly)  //cannot drop on read only field, or while cropping/capturing
            return;
    
        cropFile(file);  //crop the dropped file
    };
    
    
    //Callback when a file is selected
    const fileSelected = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log("Selected image file " + file.name);
            cropFile(file);
        }
        event.target.value = null;  //allow picking of the same value again

    };
    
 
    //Callback when an image is captured from the webcam
    const imageCaptured = (image) => {
        if (image) {  //captured an image, show cropper with the image
            setShowCropper(true);
            setImageToCrop(image);
        }
        setShowCapture(false);  //don't show the webcam capture anymore   
    };
    
    //Callback from ImageCropper when Image has been cropped
    const imageCropped = (image) => {
        if (image) {
            
            let width = parseInt(image.width);
            let height = parseInt(image.height);
            
            //Find the largest dimension and limit it
            if (props.field.aspectRatio < 1) { //wider than tall
                width = width > maxImageDimension ? maxImageDimension : width;
                height = width * props.field.aspectRatio;
            }
            else {
                height = height > maxImageDimension ? maxImageDimension : height;
                width = height / props.field.aspectRatio;
            }
          
            let imageB64 = ImageUtils.scaleImage(image, width, height, "image/png");  //to png, in case we want to crop later - preserve quality
                 
            setDisplayedImage(imageB64);  //display the new scaled image
            setImageToCrop(null);
            
            //For Application, convert to jpeg to make smaller
            let jpegImage = ImageUtils.scaleImage(image, width, height, "image/jpeg");
                        
            props.field.patronData = jpegImage;
            if (props.params.onChange)
                props.params.onChange(props.field, jpegImage);
            
            setCroppedImageData({width: parseInt(width), height: parseInt(height)}); 
            
        }
        setShowCropper(false);
    };
    
    const crop = () => {
        setImageToCrop(displayedImage);     //we read the file, set the image to crop  
        setShowCropper(true);        //turn on the cropper mode
    };
    
    const copyFromClipboard = () => {
        ImageUtils.copyImageFromClipboard((copiedImage) => {
            setImageToCrop(copiedImage);     
            setShowCropper(true);        //turn on the cropper mode
        });
    };
    
    const removeImage = () => {
        setDisplayedImage(null);  
        props.field.patronData = null;
        if (props.params.onChange)
            props.params.onChange(props.field, null);
    };
    
    
    
    const uniqueFileInputID = "fileInputID" + Application.uniqueFieldName(props.field) + (props.params.forTemplate ? "_template" : "_application");
    
    const width = window.innerWidth * 0.98;
    
    let captureWidth = width;
    if (props.field.aspectRatio >= 1.0) { //taller than haigh
        const captureHeight = window.innerHeight * .9;
        captureWidth = captureHeight / props.field.aspectRatio;
    }
    
    const previewWidth = displayImageHeight/props.field.aspectRatio;
    const previewHeight = displayImageHeight;
    
    let imageTooltip = croppedImageData ? ("Cropped Image: " + croppedImageData.width + "x" + croppedImageData.height + " ") : "Image: ";
    if (props.field.patronData) {
        if (props.field.patronData.startsWith("http"))
            imageTooltip += props.field.patronData;
        else
            imageTooltip += parseInt(props.field.patronData.length/1024) + "KB";
    }
    
    const iconLabelSize = isMobile() ? 9 : 11;
    const iconSize = isMobile() ? "medium" : "large";
    
    return (
        <div>
    
            <Popover open={showCropper || showCapture} 
                        anchorReference="anchorPosition"
                        anchorPosition={{left: window.innerWidth/2, top: 0}}
                        transformOrigin={{vertical: 'center', horizontal: 'center'}} >
                        
                <Container style={{padding: 20, width: width}}>
               
                    {showCapture ?     
                        <WebcamCapture onDone={imageCaptured} onPreCapture={() => cameraShutterAudio.play()}
                                   width={captureWidth} height={captureWidth * props.field.aspectRatio}
                                   cancelIconColor={ThemeColors.darkRed}/>
                        :
                        <ImageCropper image={imageToCrop} 
                                  aspectRatio={1/props.field.aspectRatio} 
                                  onDone={imageCropped} 
                                  cancelIconColor={ThemeColors.darkRed}/> 
                    }
                </Container>
                        
            </Popover>
    
            <GenericFieldContainer style={props.style} field={props.field} params={props.params} submitTried={props.submitTried}>

                <div style={{marginBottom: 10}}>
                    <Typography style={{color: 'gray', fontSize: 12, marginLeft: 12, marginBottom: 5}}>{label}</Typography>
                
                    <span style={{display: 'flex',  alignItems: 'center'}}>

                        <DragAndDrop handleDrop={imageFileDropped} >
                            {displayedImage ? 
                                <Tooltip title={imageTooltip}>
                                    <img style={{border: '1px solid gray'}} src={displayedImage} alt="Member" width={previewWidth} height={previewHeight}/>
                                </Tooltip>
                                :
                                <div style={{display: 'flex', background: ThemeColors.veryLightBlue, justifyContent: 'center', alignItems: 'center', border: '1px solid gray', width: previewWidth, height: previewHeight}}>
                                    <Typography align='center' style={{color: 'gray', fontStyle: 'italic', fontSize: 12}}>Drag Image File Here</Typography>                 
                                </div>
                            }
                        </DragAndDrop>

                        {isReadOnly ? null :
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', marginLeft: isMobile() ? 15 : 30, marginRight: isMobile() ? 15 : 30}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Tooltip title="Capture from Webcam">
                                        <IconButton disabled={!props.params.forPatron} onClick={() => setShowCapture(true)} >
                                            <CameraAltIcon fontSize={iconSize} style={{color: props.params.forPatron ? '#7570ff' : 'lightGray'}} />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: iconLabelSize}}>Webcam</Typography>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <input accept="image/*" id={uniqueFileInputID} style={{display: 'none'}} type="file" onChange={fileSelected}/>                                      
                                    <Tooltip title="Load from Image File">
                                        <label htmlFor={uniqueFileInputID}>
                                            <IconButton component="span">
                                                <DescriptionIcon fontSize={iconSize} style={{color: '#87854a'}}/>
                                            </IconButton>
                                        </label>
                                    </Tooltip>
                                    <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: iconLabelSize, marginTop: 4}}>File Upload</Typography>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Tooltip title="Paste from Clipboard">
                                        <IconButton onClick={copyFromClipboard} >
                                            <AssignmentReturnedIcon fontSize={iconSize}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: iconLabelSize}}>From Clipboard</Typography>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Tooltip title="Edit/Crop">
                                       <IconButton disabled={!displayedImage} onClick={crop} >
                                           <CropRotateIcon fontSize={iconSize} style={{color: displayedImage ? 'green' : 'lightGray'}} />
                                       </IconButton>
                                    </Tooltip>
                                    <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: iconLabelSize}}>Edit/Crop</Typography>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Tooltip title="Remove Image">
                                        <IconButton disabled={!displayedImage} onClick={removeImage} >
                                            <ClearOutlinedIcon fontSize={iconSize} style={{color: displayedImage ? ThemeColors.darkRed : 'lightGray'}}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: iconLabelSize}}>Clear</Typography>
                                </div>
                            </div>
                        }
                    </span>
                </div>
            </GenericFieldContainer>
        </div>

    );

} 


//---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------- SELECT FIELD --------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

    
function SelectField(props) {

    const readOnly = readOnlyField(props.field, props.params);

    let autoCompleteWidth = window.innerWidth * 0.5;
    if (autoCompleteWidth > 500)
        autoCompleteWidth = 500;

    return (
        <GenericFieldContainer style={props.style} field={props.field} submitTried={props.submitTried} params={props.params} invalid={false}>
    
            {props.field.displayAsList ?
                <div>
                    <Autocomplete
                        size='small'
                        style={{width: autoCompleteWidth}}
                        value={props.field.patronData}
                        onChange={(event, newValue) => { props.field.patronData = newValue; 
                                                         if (props.params.onChange)
                                                            props.params.onChange(props.field, newValue);}  
                                                        }
                        options={props.field.selectableItems}
                        blurOnSelect
                        renderInput={(params) => <TextField {...params} label={props.field.label + (props.field.required ? " *" : "")} variant="outlined" InputLabelProps={{ shrink: true }} />}
                    /> 
                </div>
                :
                <div>
                    <Typography style={{color: 'gray', fontSize: 12, marginLeft: 12, marginBottom: 5}}>{props.field.label + (props.field.required ? " *" : "")}</Typography>
                    <div style={{marginLeft: 4}}>
                        <FormControl component="fieldset" disabled={readOnly}>
                            <RadioGroup value={props.field.patronData} onChange={(event) => {

                                    props.field.patronData = event.target.value;
                                    if (props.params.onChange)
                                        props.params.onChange(props.field, event.target.value);

                                }}>

                                {props.field.selectableItems.map((item, index) => 
                                    <FormControlLabel key={index} value={item} control={<Radio color="primary" />} label={item} />
                                )}

                            </RadioGroup>
                        </FormControl>  
                    </div>
                </div>
            }
        </GenericFieldContainer>

    );
    
    
}    




//---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------- CHECKBOX FIELD ------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

    
function CheckboxField(props) {
        
    const initialValue = props.field.patronData;
    const readOnly = readOnlyField(props.field, props.params);

    const initiallySelected = initialValue === "true" ? true : false;
    
    return (
        <GenericFieldContainer style={props.style} field={props.field} submitTried={props.submitTried} params={props.params} invalid={false}>
    
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: 0, marginLeft: -5}}>
            
                <Checkbox defaultChecked={initiallySelected} disabled={readOnly} color='primary' 
                          onChange={(event) => { 
        
                                let val = event.target.checked ? "true" : "false";
                                if (props.field.required && val === "false")  //A required checkbox field MUST be checked
                                    val = null;

                                props.field.patronData = val;
                                if (props.params.onChange)
                                    props.params.onChange(props.field, val);                      
        
                            }}/>   

                <Typography variant='body1' align='left'>{props.field.label + (props.field.required ? " *" : "")}</Typography>                        
             </div> 
        </GenericFieldContainer>

    );
    
    
}    



//---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------- ADDRESS FIELD -------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

//internal format:  Number|Street|Unit|City|State|Country|PostCode
    
function AddressField(props) {
         
    let initialValue = props.field.patronData;
    if (!initialValue)
        initialValue = "||||||";
    
    let componentValues = initialValue.split("|");
    
    const req = props.field.required ? " *" : ""
    
    let components;
    let i = 0;
    if (props.field.splitAddressLine) {
        components = [{label: "House/Building Number" + req, value: componentValues[i], xs: 7, sm: 5, md: 3},
                      {label: "Street Name" + req,           value: componentValues[i+1], xs: 12, sm: 9, md: 7},
                      {label: "Unit/Apt/Suite" + req,        value: componentValues[i+2], xs: 7, sm: 3, md: 2, isAlwaysOptional: 'true'}];
                      
        i=3;
    }
    else {
        components = [{label: "Address" + req, value: componentValues[i], xs: 12, sm: 12, md: 12}];
        i=1;
    }
    
    components.push({label: "City/Town" + req,             value: componentValues[i], xs: 12, sm: 7, md: 5});
    components.push({label: "State/Province" + req,        value: componentValues[i+1], xs: 12, sm: 5, md: 3});
    components.push({label: "Postal Code" + req,           value: componentValues[i+2], xs: 5, sm: 5, md: 2});
    
    if (props.field.includeCountry)
        components.push({label: "Country" + req,           value: componentValues[i+3], xs: 7, sm: 7, md: 2});
        
    const readOnly = readOnlyField(props.field, props.params);

    return (  
        <GenericMultiTextFields {...props} isReadOnly={readOnly} components={components}/>
    );
    
    
}    


//---------------------------------------------------------------------------------------------------------------------------------
//------------------------------------------------- EMERGENCY CONTACT FIELD -------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

//internal format: Name|Number
    
function EmergencyContactField(props) {
                 
    let initialValue = props.field.patronData;
    if (!initialValue)
        initialValue = "|";
    
    let componentValues = initialValue.split("|");
    
    const req = props.field.required ? " *" : ""
    
    let components = [{label: "Emergency Contact Name" + req,   value: componentValues[0], xs: 12, sm: 6},
                      {label: "Emergency Contact Phone" + req,  value: componentValues[1], xs: 12, sm: 6}
                     ];
                     
    const readOnly = readOnlyField(props.field, props.params);

    return (
        <GenericMultiTextFields {...props} isReadOnly={readOnly} components={components}/>
    );
    
    
}    


//---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------- DOCUMENT FIELD ------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

    
function DocumentField(props) {
        
    const isReadOnly = readOnlyField(props.field, props.params);
        
    const [uploadInProgress, setUploadInProgress] = useState(false);   
    const [fileInfo, setFileInfo] = useState(null);   
       
    const label = props.field.label + (props.field.required ? " *" : "");
    
    const documentID = props.field.patronData;
    
    //Callback when server finished uploading, newDocumentID is the new ID, or null if deleted
    const uploadComplete = (newDocumentID, documentSize) => {
        setUploadInProgress(false);
        props.field.patronData = newDocumentID;
        if (props.params.onChange)
            props.params.onChange(props.field, newDocumentID);
        setFileInfo(null);  //refetch file info
    };
    
    
    //Callback when File is selected, upload file to server with old documentID (null if nothing has been uploaded yet)
    const uploadFile = (file) => {        
        props.params.documentUploader(documentID, file, uploadComplete);
        setUploadInProgress(true);
    };
    
    //Callback when a file is dropped
    const fileDropped = (file) => {
        if (isReadOnly)  //cannot drop on read only field
            return;
    
        uploadFile(file);
    };
    
    
    //Callback when a file is selected
    const fileSelected = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log("Selected file " + file.name);
            uploadFile(file);
        }
        event.target.value = null;  //allow picking of the same value again
    };
    
    const downloadFile = () => {
        props.params.documentDownloader(documentID);
    };
   
    const removeFile = () => {
        uploadFile(null);       //delete any existing file
    };

    
    const documentInfoCallback = (docInfo) => {  //docInfo is a JSON of the backend Document object
        setFileInfo(docInfo);
    }

    if (documentID && fileInfo === null) {
        props.params.documentDownloader(documentID, documentInfoCallback);
    }
    
    
    const uniqueFileInputID = "fileInputID" + Application.uniqueFieldName(props.field) + (props.params.forTemplate ? "_template" : "_application");;
    
    const iconLabelSize = isMobile() ? 9 : 12;
    const iconSize = isMobile() ? "medium" : "large";

    const fileSize = fileInfo ? parseInt(fileInfo.documentSize/1024) + "KB" : "";

    
    return (
        <div>
    
            <GenericFieldContainer style={props.style} field={props.field} params={props.params} submitTried={props.submitTried}>

                <div style={{marginBottom: 10}}>
                    <Typography style={{color: 'gray', fontSize: 12, marginLeft: 12, marginBottom: 5}}>{label}</Typography>
                
                    <span style={{display: 'flex',  alignItems: 'center'}}>

                        <DragAndDrop handleDrop={fileDropped} >
                            <div style={{display: 'flex', background: ThemeColors.veryLightBlue, justifyContent: 'center', alignItems: 'center', padding: 3, border: '1px solid gray', width: displayDocWidth, height: displayDocHeight}}>
                                
                                {uploadInProgress ? 
                                    <CircularProgress/>
                                    :
                                    (documentID ? 
                                      <div>
                                            <Typography align='center' style={{color: 'green', fontSize: 14}}>✓ File Uploaded</Typography>
                                            {fileInfo ? <Typography align='center' style={{fontSize: 12}}>{fileInfo.originalFilename + " (" + fileSize + ")"}</Typography> : null}      
                                      </div>
                                      :
                                      <Typography align='center' style={{color: 'gray', fontStyle: 'italic', fontSize: 12}}>Drag File Here</Typography>
                                    )
                               }
                            </div>                         
                        </DragAndDrop>

                        <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px', marginLeft: isMobile() ? 15 : 30, marginRight: isMobile() ? 15 : 30}}>
                               
                                {isReadOnly ? null :
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <input id={uniqueFileInputID} style={{display: 'none'}} type="file" onChange={fileSelected}/>                                      
                                        <Tooltip title="Browse for File">
                                            <label htmlFor={uniqueFileInputID}>
                                                <IconButton component="span">
                                                    <DescriptionIcon fontSize={iconSize} style={{color: '#87854a'}}/>
                                                </IconButton>
                                            </label>
                                        </Tooltip>
                                        <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: iconLabelSize, marginTop: 4}}>Browse</Typography>
                                    </div>
                                }

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Tooltip title="Download File">
                                        <IconButton disabled={!documentID} onClick={downloadFile} >
                                            <GetAppIcon fontSize={iconSize} style={{color: documentID ? 'black' : 'lightGray'}}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: iconLabelSize}}>Download</Typography>
                                </div>

                                {isReadOnly ? null :
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Tooltip title="Remove File">
                                            <IconButton disabled={!documentID} onClick={removeFile} >
                                                <ClearOutlinedIcon fontSize={iconSize} style={{color: documentID ? ThemeColors.darkRed : 'lightGray'}}/>
                                            </IconButton>
                                        </Tooltip>
                                        <Typography align='center' style={{color: ThemeColors.mediumGray, fontSize: iconLabelSize}}>Remove</Typography>
                                    </div>
                                }
                        </div>
                        
                    </span>
                </div>
            </GenericFieldContainer>
        </div>

    );

} 



//---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------- SIGNATURE FIELD -----------------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

    
function SignatureField(props) {
        
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);  //initial window width, when rendered 

    //Only patrons edit the field, others just view the signature image
    const forEditing = props.params.forPatron;
       
    const label = props.field.label + (props.field.required ? " *" : "");
    
    const sigCanvas = useRef(null);  //Reference to the Signature component so we can call its API
        
    const strokeComplete = () => {  //called when user finishes one stroke, we have at least a fragment 
        
        const trimmedImage = sigCanvas.current.toDataURL('image/png');
        props.field.patronData = trimmedImage;
        if (props.params.onChange)
            props.params.onChange(props.field, trimmedImage);
    }

    const clear = () => {
        sigCanvas.current.clear();  //clear the canvas
        props.field.patronData = null;
        if (props.params.onChange)
            props.params.onChange(props.field, null);
    };

    const iconLabelSize = isMobile() ? 9 : 11;
    const iconSize = isMobile() ? "medium" : "large";
    const hasSignature = props.field.patronData && props.field.patronData.length > 0;

    let canvasWidth = window.innerWidth * (props.params.forPatron ? 0.75 : 0.5);
    if (canvasWidth > 1000)
        canvasWidth = 1000;
    const canvasHeight = signatureCanvasHeight;

    //when resized, the internal canvas clears (annoying!) so we must redraw the image - the ratio is required!
    if (forEditing && (window.innerWidth !== windowWidth)) { 
        setWindowWidth(window.innerWidth);
        if (hasSignature && sigCanvas.current)
            setTimeout(sigCanvas.current.fromDataURL(props.field.patronData, {ratio: 1}), 10);
    }

    return (
        <div>
    
            <GenericFieldContainer style={props.style} field={props.field} params={props.params} submitTried={props.submitTried}>

                <div style={{marginBottom: 10}}>
                    <Typography style={{color: 'gray', fontSize: 12, marginLeft: 12, marginBottom: 5}}>{label}</Typography>
                
                    <span style={{display: 'flex',  alignItems: 'center'}}>

                        <div style={{border: '1px solid gray', backgroundColor: ThemeColors.signaturePadColor, minHeight: canvasHeight, minWidth: canvasWidth}}>
                            {forEditing ?
                                <SignatureCanvas ref={sigCanvas} 
                                                 penColor={ThemeColors.signatureStrokeColor} 
                                                 onEnd={strokeComplete}
                                                 canvasProps={{width: canvasWidth, height: canvasHeight}}
                                                />
                                :
                                hasSignature ? <img style ={{padding: 10}} src={props.field.patronData} alt="Signature" height={canvasHeight}/> : null
                            }
                        </div>

                        {!forEditing ? null :
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Tooltip title="Clear Signature">
                                    <IconButton disabled={!hasSignature} onClick={clear} >
                                        <ClearOutlinedIcon fontSize={iconSize} style={{color: hasSignature ? ThemeColors.darkRed : 'lightGray'}}/>
                                    </IconButton>
                                </Tooltip>
                                <Typography align='center' style={{color: 'gray', fontSize: iconLabelSize}}>Clear</Typography>
                            </div>
                        }
                    </span>
                </div>
            </GenericFieldContainer>
        </div>

    );
  
} 




//---------------------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------- CALCULATION RESULT FIELD --------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

    
function CalcResultField(props) {
               
    let data = props.field.patronData ? props.field.patronData : "";

    //remove these prefixes
    if (data.startsWith("STRING:"))
        data = data.slice("STRING:".length);
    else if (data.startsWith("NUMBER:")) {
        data = data.slice("NUMBER:".length);
        const numeric = parseFloat(data);
        data = numeric.toFixed(props.field.numericPrecision);
    }

    const label = props.field.label ? props.field.label : "";

    return (
        <GenericFieldContainer style={props.style} field={props.field} submitTried={props.submitTried} params={props.params} invalid={false}>
            <div>
                <Typography style={{fontSize: 16}}>{label + data}</Typography>
            </div>
        </GenericFieldContainer>

    );
    
    
}    



//---------------------------------------------------------------------------------------------------------------------------------
//---------------------------------------RICH TEXT EDITOR / READ ONLY HTML FIELD---------------------------------------------------
//---------------------------------------------------------------------------------------------------------------------------------

    
function RichTextHtmlField(props) {
               
    const backgroundColor = props.field.backgroundColor ? props.field.backgroundColor : 'white';

    return (
        <GenericFieldContainer style={{...props.style, backgroundColor: backgroundColor}} field={props.field} submitTried={props.submitTried} params={props.params} invalid={false}>

            <RichTextEditor
                draftContent={props.field.patronData}     
                editing={props.params.forTemplate}  
                onChange={ (draftJson) => {
                    props.field.patronData = draftJson;  //no need to re-render, this is read only
                }}
                
            />
        </GenericFieldContainer>
    );

}    

