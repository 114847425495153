import React, { Fragment } from 'react';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';

import { Tooltip, Typography, Button } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { Application } from '../models/Application'
import { ApplicationMessagePopover } from '../components/ApplicationMessagePopover'

import { RestComponent, ManageDateField, DateUtils } from 'react-frontend-utils' 
import { ThemeColors } from '../Theme'

import { ManageTextField } from 'react-frontend-utils'

// 
// SYNC PAGE 3 - Receives the Server Data from Page 3, and passes to the backend as a PPSyncComplete object after confirming. 
// Depending on the type, we use a different rest method:
//      CREATE  - POST
//      REPLACE - PUT
//      UPDATE  - PATCH
//
//  Also passed to this page is a callback "onMembershipCreate" that takes the new Membership ID when sync'ed
//
// 
//   Server Data from Page 3:
//   
// {
//      membership  - Membership to sync to (null for new)
//      householdAddress - address from the household
//      email - email from the household
//      replace  - true to replace this Membership
//      membershipFieldsToSync - array of fields names that are to be synced
//      changingMembers - array of pruned Member objects
//      newMembers - array of pruned Applicant objects, that will be added to the Membership
//      deleteMembersCount - number of members to delete
//      abandonedApplicants - number of Applicants left abandoned (not added or replaced)
//      unchangedMembers -   count of Members left unchanged
//      finalActions - PPSyncFinalActions object
//  }
//  
//  
//  To backend:  PPSyncComplete object:
//  
//    {
//      id: the existing Membership ID, or the new one in case of CREATE
//      lastTransaction: the Membership lastTransaction value, null if new
//      membershipFieldsToSync - array of fields names that are to be synced
//      changingMembers: array of pruned Member objects - with a field that identifies the matched Applicant. This field is null unless UPDATE
//      newMembers: array of pruned Applicant objects, that will be added to the Membership
//  }
//  
//  
//
//

const membershipIDRegex = /^[a-zA-Z0-9@+._ ,]+$/
const replaceRegex = /[^a-zA-Z0-9._ ,]/g

const allowedMembershipIDChars = "A-Z a-z 0-9 _ . , and space";


function plural(num) {
    return (num !== 1 ? "s" : "")
}

export class SyncPage4 extends RestComponent {

    
    _id;             //Application id 
    _expirationDate = "Never";      // Expiration Date

    _prevPageCallback;
    
    constructor(props) {
        super(props);
    
        this._id = props.id;        //application id
        
        this._prevPageCallback = props.prevPageCallback;  //callback that returns to Page 3
        
        this.state.dataToServer = props.dataToServer;  
      
        if (props.dataToServer.finalActions.expirationDate)
            this._expirationDate = DateUtils.dateFormat(DateUtils.parseJsonDate(props.dataToServer.finalActions.expirationDate));

        this.state.overrideExpDate = false;
        this.state.newExpDate = undefined;


        const defaultNewId = this.state.dataToServer.finalActions.defaultNewMembershipID;

        this.state.newMembershipID = defaultNewId ? defaultNewId : "";  //default new Membership ID, if null, blank
        this.state.newMembershipID = this.state.newMembershipID.replace(replaceRegex, '');      // remove unwanted chars

        this.state.isFinished = false;
        this.state.commentPopupOpen = false;
    }
    
    componentDidMount() {
        super.componentDidMount(); 
        
        // Browser back button - go back 
       window.addEventListener("popstate", this._prevPageCallback);
    }
   
    componentWillUnmount() {
        super.componentWillUnmount();       
        window.removeEventListener("popstate", this._prevPageCallback);
    }
    
    
    //User presses the sync button
    _syncProceed = () => {
        this.setState({commentPopupOpen: true, releaseToState: Application.State.PROCESSED});
    }
    
    //Processor sets a patronMessage, internalComment, and wants to send the email to patron
    _commentOkCallback = (patronMessage, internalComment) => {
        this._doSync(patronMessage, internalComment, true);  
        this.setState({commentPopupOpen: false, releaseToState: null});
    }
    
    //Processor sets a internalComment, and suppresses the email send
    _commentSkipCallback = (internalComment) => {
        this._doSync(null, internalComment, false); 
        this.setState({commentPopupOpen: false, releaseToState: null});
    }

    _getPrintedPassCount = () => {
        
        let printedPasses = 0;
        for (const applicant of this.state.dataToServer.newMembers) {
            if (applicant.printPass)
                printedPasses++;
        }   

        for (let member of this.state.dataToServer.changingMembers) {
            if (member.matchedApplicant && member.matchedApplicant.printPass)
                printedPasses++;
        }

        return printedPasses;
    }
    
    // If there are passes to print, we need an address either from the household or the membership field, if it is to sync
    _missingAddressForPrint = () => { 
        const missingAddress = !this.state.dataToServer.membershipFieldsToSync.includes("address") || !this.state.dataToServer.householdAddress
        return (missingAddress && this._getPrintedPassCount() > 0);
    }

    // If there are ePasses, we need an email either from the household or the membership field, if it is to sync
    _missingEmail = () => { 
        const missingEmail = !this.state.dataToServer.membershipFieldsToSync.includes("email") || !this.state.dataToServer.email
        return (missingEmail && this.state.dataToServer.finalActions.ePassesWillBeSent);
    }

    _doSync = (patronMessage, internalComment, sendEmail) => {
        
        let completeSyncData;
        let operation;
        
        if (!this.state.dataToServer.membership) { //CREATE OPERATION

            const newMembershipID = this.state.newMembershipID.trim();
            if (!newMembershipID || newMembershipID.length > 254 || !newMembershipID.match(membershipIDRegex)) {
                this.showConfirmAlert("Membership ID Error", "New Membership ID must not be blank or longer than 254 characters, and must contain only the following characters: " + allowedMembershipIDChars, 'red');
                return;
            }

            if (this._missingAddressForPrint()) {
                this.showConfirmAlert("Membership Address Error", "Missing Membership address which is required for pass printing", 'red');
                return;
            }

            if (this._missingEmail()) {
                this.showConfirmAlert("Membership Email Error", "Missing Membership email which is required for sending ePasses", 'red');
                return;
            }

            completeSyncData = {membershipID: newMembershipID,
                                lastTransaction: null,
                                membershipFieldsToSync: this.state.dataToServer.membershipFieldsToSync,
                                newMembers: this.state.dataToServer.newMembers,
                                overrideExpDate: this.state.overrideExpDate,
                                newExpDate: this.state.newExpDate,
                                patronMessage: patronMessage, 
                                internalComment: internalComment, 
                                sendEmail: sendEmail
                               };
            operation = "POST";
                                       
        }
        else if (this.state.dataToServer.replace) {  //REPLACE OPERATION

            if (this._missingAddressForPrint()) {
                this.showConfirmAlert("Membership Address Error", "Missing Membership address which is required for pass printing", 'red');
                return;
            }
          
            completeSyncData = {membershipID: this.state.dataToServer.membership.id,
                                lastTransaction: this.state.dataToServer.membership.lastTransaction,
                                membershipFieldsToSync: this.state.dataToServer.membershipFieldsToSync,
                                newMembers: this.state.dataToServer.newMembers,
                                overrideExpDate: this.state.overrideExpDate,
                                newExpDate: this.state.newExpDate,
                                patronMessage: patronMessage, 
                                internalComment: internalComment, 
                                sendEmail: sendEmail
                               };
            operation = "PUT";
          
        }
        else { //UPDATE OPERATION
            
            completeSyncData = {membershipID: this.state.dataToServer.membership.id,
                                lastTransaction: this.state.dataToServer.membership.lastTransaction,
                                membershipFieldsToSync: this.state.dataToServer.membershipFieldsToSync,
                                changingMembers: this.state.dataToServer.changingMembers,
                                newMembers: this.state.dataToServer.newMembers,
                                overrideExpDate: this.state.overrideExpDate,
                                newExpDate: this.state.newExpDate,
                                patronMessage: patronMessage, 
                                internalComment: internalComment, 
                                sendEmail: sendEmail
                                };  
            operation = "PATCH";
        }
        
        this.incrementBusy();
        this.secureJSONFetch("/ap/applications/" + this._id + "/ppsync/complete", {method: operation, body: JSON.stringify(completeSyncData)},
                            (response) => this._completeCallback(response, operation), this._syncErrorCallback);   
        
    }
    
    _completeCallback = (response, operation) => {
        window.removeEventListener("popstate", this._prevPageCallback);  //remove back button capability 
        this.setState({isFinished: true});
        this.decrementBusy();

        if (operation === "POST")  //On create, notify Sync page we have a new Membership ID created 
            this.props.onMembershipCreate(this.state.newMembershipID);

           
        window.applicationTab.refresh(this._id);  //call the refresh method on the parent window to show new status
    }
    

    _syncErrorCallback = (error) => {
        this.showConfirmAlert("Error", error, 'red');
        this.decrementBusy();
    }

    _dateParseError = (label, error) => {
        this.showConfirmAlert("Error in Date Field \"" + label + "\"", error, 'red');
    }

    _expDateChange = (json, date) => {
        this.setState({overrideExpDate: true, newExpDate: date});
    }
 
    render() {
         
        if (this.state.isBusy)
            return this.getBusyComponent('center', {marginTop: 20});
     
        if (this.state.isFinished) {
            return  <div style={{textAlign: 'center', marginTop: 40}}>
                            <Typography variant="h4" style={{color: 'green', fontWeight: 'bold', marginBottom: 10}}>
                                <span style={{marginRight: 20}}>✓</span>PoolPass Sync Complete
                            </Typography>  
                            <Typography variant="h6">Please close this tab</Typography>  
                    </div>;
        }

        const newCount = this.state.dataToServer.newMembers.length;

        const printedPasses = this._getPrintedPassCount();

        let message;
        let syncButtonTitle;
        let membershipChanges;
        let memberChanges;
        const abandoned = this.state.dataToServer.abandonedApplicants;
        const unchangedMembers = this.state.dataToServer.unchangedMembers;

        //PPSyncFinalActions
        const newLimitedMembers = this.state.dataToServer.finalActions.newLimitedMembers;
        const willPrintNewLimitedMembers = this.state.dataToServer.finalActions.newLimitedMembersPrinted;
        const guestPassesAfterSync = this.state.dataToServer.finalActions.guestPassesAfterSync;
        const ePasses = this.state.dataToServer.finalActions.ePassesWillBeSent;
        
        if (!this.state.dataToServer.membership) { //new Membership
            message = "Please enter a Membership ID for the Membership to be created, then press CREATE to create the Membership and finish.";
            syncButtonTitle = "Create and Approve";
            membershipChanges = "Creating new Membership";
            
            memberChanges = <Typography variant="h6" style={{marginBottom: 20}}>
                                {"Creating " + newCount + " new Member" + plural(newCount)}
                            </Typography>;
        }
        else if (this.state.dataToServer.replace) {
            message = "You are replacing all data in a Membership. Please review changes and press REPLACE to complete";
            syncButtonTitle = "Replace and Approve";
            membershipChanges = "Replacing Membership ID: " + this.state.dataToServer.membership.id;
            
            memberChanges = <Typography variant="h6" style={{marginBottom: 20}}>
                                {"Deleting all previous Members and creating " + newCount + " new Member" + plural(newCount)}
                            </Typography>;

        }
        else {
            message = "You are updating an existing Membership. Please review changes and press SYNC to complete.";
            
            if (this.state.dataToServer.membership.suspended)
                message += " Note: this Membership is suspended. Approving this Application will not un-suspend the Membership.";
            
            syncButtonTitle = "Sync and Approve";
            
            let modifiedCount = 0;
            let deleteCount = 0;
            for (let member of this.state.dataToServer.changingMembers) {
                if (member.markedForDelete)
                    deleteCount++;
                else if (member.matchedApplicant)
                    modifiedCount++;
            }

            const unchangedString = unchangedMembers > 0 ? unchangedMembers + " current Member" + plural(unchangedMembers) + " will be kept (barcode" + plural(unchangedMembers) + " unchanged)": null;
            const updateString = modifiedCount > 0 ? modifiedCount + " current Member" + plural(modifiedCount) + " will be updated with new Applicant data (barcode" + plural(modifiedCount) + " unchanged)" : null;
            const newString = newCount > 0 ? newCount + " Applicant" + plural(newCount) + " will be added as new Member" + plural(newCount) + " (new barcode" + plural(newCount) + " assigned)" : null; 
            const deleteString = deleteCount > 0 ? deleteCount + " current Member" + plural(deleteCount) + " will be deleted (barcode" + plural(deleteCount) + " deleted)" : null; 
            const abandonedString = abandoned > 0 ? abandoned + " Applicant" + plural(abandoned) + " will be ignored" : null;
            const newLimitedString = newLimitedMembers > 0 ? newLimitedMembers + " new Limited Member" + plural(newLimitedMembers) + " will be added (new barcode" + plural(newLimitedMembers) + " assigned)" : null;
            
            membershipChanges = "Modifying Membership ID: " + this.state.dataToServer.membership.id;
            memberChanges =  <div>
                                <Typography variant="h6" style={{marginBottom: 10}}>Member Changes:</Typography>  
                                <Typography variant='body1' style={{color: 'black'}}>{unchangedString}</Typography>
                                <Typography variant='body1' style={{color: 'blue'}}>{updateString}</Typography>
                                <Typography variant='body1' style={{color: 'green'}}>{newString}</Typography>
                                <Typography variant='body1' style={{color: 'red'}}>{deleteString}</Typography>
                                <Typography variant='body1' style={{color: 'gray'}}>{abandonedString}</Typography>
                                <Typography variant='body1' style={{color: 'purple'}}>{newLimitedString}</Typography>
                            </div>;
        }
        
        
        
        return (
            <Fragment>
                {this.getConfirmAlertComponent()}
                
                <ApplicationMessagePopover isOpen={this.state.commentPopupOpen} title="Approval Comments" 
                                           okCallback={this._commentOkCallback} skipCallback={this._commentSkipCallback} cancelCallback={() => {this.setState({commentPopupOpen: false});}} />
      
                
                <div style={{display: 'flex', float: 'right', marginRight: 10, marginBottom: 20, marginLeft: 20}}>
                   <Tooltip title={"Go back to select Membership fields again"}>
                        <Button variant='outlined' style={{borderColor: ThemeColors.darkGray, color: ThemeColors.darkGray, marginRight: 10, width: 200}}
                                onClick={this._prevPageCallback} startIcon={<ArrowBackIosIcon/>}>
                            Go Back
                        </Button>
                   </Tooltip> 
                </div>
                                    
                <Typography variant="body1" style={{marginBottom: 10, color: ThemeColors.mediumGray}}>{message}</Typography>  
                
                <Typography variant="h6" style={{marginBottom: 10}}>{membershipChanges}</Typography>  
                {this.state.dataToServer.membership ? null :

                    <ManageTextField label="New Membership ID"

                        style={{marginBottom: 30, maxWidth: 400}}
                        json="newMembershipID" 
                        initialValue={this.state.newMembershipID}
                        autoAccept={true}
                        onFieldChange={(fieldName, userValue) => {
                            this.setState({newMembershipID: userValue});
                        }}/>
                }
                                
                {memberChanges}
               
                <Typography variant="h6" style={{marginBottom: 10, marginTop: 30}}>Passes:</Typography>  

                <Typography variant='body1' style={{color: 'black'}}>{"Member Classic Passes selected to be Printed: " + printedPasses}</Typography>
                {newLimitedMembers > 0 ? 
                    <Typography variant='body1' style={{color: 'black'}}>{"New Limited Member Classic Passes to be Printed: " + (willPrintNewLimitedMembers ? newLimitedMembers : 0)}</Typography>
                    : null
                }
                <Typography variant='body1' style={{color: 'black'}}>{"ePasses will be sent: " + ePasses}</Typography>
                <Typography variant='body1' style={{color: 'black'}}>{"Virtual Guest Passes after sync: " + guestPassesAfterSync}</Typography>


                <Typography variant="h6" style={{marginBottom: 10, marginTop: 30}}>Membership Expiration Date:</Typography>  

                <ManageDateField autoAccept={true} hasNever={true} json="expDate"
                                initialValue={this._expirationDate}
                                style={{maxWidth: 500}}
                                neverText="Never"
                                minYear={DateUtils.currentYear()}
                                maxYear={DateUtils.currentYear()+50}
                                label="Override Expiration Date" onFieldChange={this._expDateChange} 
                                onParseError={this._dateParseError} 
                                calendarColor={ThemeColors.calendarColor}/>  

                <div style={{display: 'flex', justifyContent: 'center', marginTop: 40}}>
                    <Button variant='contained' onClick={this._syncProceed} style={{width: 200, backgroundColor: 'green', color: 'white'}}>
                        {syncButtonTitle}
                    </Button>
                </div>

                
            </Fragment>    
        );
    }
    
    

}


export default withCookies(withRouter(SyncPage4));


